/* Body */
body {
  min-width: 320px;
  overflow-x: hidden;
  //background-color: red;
}

/* Lazy load */
.lazy {
  transition: all 0.5s linear;
}

/* Home Section */
#exception1 {
  margin-top: 80px;
}

#home {
  position: relative;
  height: auto;
  min-height: 460px;
  overflow-y: hidden;
}

#animation_container,
#canvas,
#dom_overlay_container {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%!important;
  height: auto;
}

/* Navigation */
.navbar-collapse.show {
  background: $bgColorBtnIntegration!important;
}

.navbar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9100;
  border: none;
  background-color: transparent!important;
}

.navbar-brand {
  min-height: 155px;
  width: 160px;
  margin-top: -8px;
  margin-left: 15px;
  background-color: #ffffff;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.75);
}

.navbar-brand img {
  display: block;
  margin: 15px auto 0;
  width: 100px;
  height: auto;
}

.navbar .navbar-nav .nav-link {
  font-size: $fontSizeNavItem;
  font-family: $fontName1;
  color: $fontColorNavItem;
  text-align: center;
}

.navbar .navbar-nav .nav-link:hover {
  color: $fontColorNavItemHover;
}

.navbar .navbar-nav .nav-link:focus {
  color: $fontColorNavItemFocus;
}

.navbar-toggler-icon {
  background-image: url('../img/icon/menu_button_icon.svg')!important;
}

.wrHomeContent {
  margin: 60px auto;
  max-width: 740px;
}

.homeTitle {
  font-size: $fontSizeHomeTitle;
  font-family: $fontName1;
  color: $fontColorFirst;
  text-align: center;
}

.homeSubtitle {
  font-size: $fontSizeHomeSubtitle;
  font-family: $fontName1;
  color: $fontColorSecond;
  text-align: center;
}


.change-language-wr {
  padding: 3px;
}

.change-language {
  position: relative;
  min-height: 32px;
  margin-left: 18px;
  width: 80px;
  padding: 5px 0 0 40px;
  font-family: Montserrat_Bold, Arial, sans-serif;
  text-transform: uppercase;
  color: #fff;
  border-radius: 16px;
  background: #1ea6b6 url('../img/globe.svg') 8px 5px no-repeat;
  background-size: 22px;
  cursor: pointer;
}

.change-language-line {
  z-index: -1;
  content: '';
  position: absolute;
  top: 16px;
  left: 0;
  height: 0;
  width: 80px;
  background-color: #fff;
  transition: all 0.2s ease;
}

.hidden-lang-list {
  z-index: -1;
  position: absolute;
  display: block;
  height: 0;
  top: 16px;
  left: 0;
  width: 80px;
  color: #cccccc;
  list-style: none;
  background-color: #fff;
  border-radius: 0 0 16px 16px;
  transition: all 0.3s ease;
}

.change-language-wr:hover .hidden-lang-list {
  height: 98px;
  padding: 20px 0 5px 0;
}

.hidden-lang-list li {
  padding-left: 28px;
  font-size: 0;
  transition: all 0.3s ease;
}

.change-language-wr:hover li {
  font-size: 16px;
}

.hidden-lang-list li:hover {
  transform: scale(1.15);
}

.curLang {
  color: #1ea6b6;
}

ul.hidden-lang-mobile-list {
  max-height: 24px;
  display: flex;
  font-family: Montserrat_Bold, Arial, sans-serif;
  color: #ffffff;
  max-width: 100%;
  text-transform: uppercase;
}

ul.hidden-lang-mobile-list li {
  margin: 0 10px;
}

ul.hidden-lang-mobile-list li:first-child {
  margin-top: 0;
}

.navbar-nav {
  align-items: center;
}


/* HowTo Section */
#howto {
  padding: 90px 0 130px;
}

.howtoCol1 {
  text-align: center;
}

.howtoCol1 img {
  width: 100%;
  height: auto;
}

.wrSectionTitle {
  text-align: center;
}

.sectionTitle {
  font-size: $fontSizeSectionTitle;
  font-family: $fontName1;
  color: $fontColorSectionTitle;
  line-height: 100%;
  background-image: linear-gradient(to right, $fontColorThird 100%, transparent 0);
  background-position: center .9em;
  background-repeat: repeat-x;
  background-size: 100% 8px;
}

.howtoCol2 {
  margin-left: 60px;
}

.howtoCol2 .sectionTitle {
  color: $fontColorHowtoTitle;
}

.wrHowtoText {
  margin: 35px 0;
}

.howtoText {
  font-size: $fontSizeText;
  font-family: $fontName2;
  color: $fontColorText;
  text-align: justify;
}

.wrHowtoSteps {
  display: none;
  padding: 50px 25px;
  background: #d2edf0;
}

.wrHowtoSteps img {
  width: 100%;
  height: auto;
}

/* Marketplaces Section */
#marketplacess {
  border: 1px solid $bgColorMarketplaces;
  padding: 45px 0;
  background: $bgColorMarketplaces;
}

.wrMarketplaces {
  padding: 30px 15px;
  background: $bgColorWrMarketplaces;
}

.marketplaces {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 35px 0 0 0;
  padding: 0;
}

.marketplace {
  width: 30%;
  margin: 10px 0;
  padding: 0 5px;
  list-style: none;
}

.linkMarketplace {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  position: relative;
}

.linkMarketplace:hover,
.linkMarketplace:focus {
  text-decoration: none;
}

.linkMarketplace::before {
  display: block;
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
  border: 5px solid transparent;
  border-left: 5px solid $fontColorSecond;
  content: '';
  opacity: 0;
}

.marketplaceIcon {
  display: flex;
  align-items: center;
  width: 60px;
  margin-right: 30px;
}

.marketplaceIcon img {
  width: 100%;
  height: auto;
}

.marketplaceTitle {
  border-bottom: 1px solid transparent;
  font-size: $fontSizeText;
  line-height: 1;
  font-family: $fontName2;
  color: $fontColorText;
}

.linkMarketplace:hover .marketplaceTitle,
.linkMarketplace:focus .marketplaceTitle,
.linkMarketplace:active .marketplaceTitle {
  line-height: 1;
  color: $fontColorSecond;
  border-bottom: 1px solid $fontColorSecond;
}

.linkMarketplace:hover:before,
.linkMarketplace:focus:before,
.linkMarketplace:active:before {
  opacity: 1;
}

/* Puzzle Section */
.wrPuzzle {
  width: 100%;
}

.wrPuzzle img {
  width: 100%;
  height: auto;
}

/* Integration Section */
#integration {
  padding: 45px 0 85px;
}

/* AddService Section */
#addservice {
  padding: 45px 0;
  background: $bgColorAddService;
}

.wrServiceCards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 60px;
}

.wrCardService {
  width: 200px;
  margin: 15px;
}

.wrCardServiceImg {
  height: 206px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-bottom: 4px solid $fontColorSecond;
  background: $bgColorCard1;
  text-align: center;
  transition: all 0.2s ease;
}

.product-conf img {
  max-width: 105px;
}

.product-maint img {
  max-width: 86px;
}

.translation img {
  max-width: 160px;
}

.design img {
  max-width: 148px;
}

.develop img {
  max-width: 130px;
}

.content img {
  max-width: 150px;
}

.optimization img {
  max-width: 123px;
}

.wrCardServiceText {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 105px;
  height: 132px;
  padding: 25px 15px;
  background: $bgColorCard2;
  font-size: $fontSizeText;
  font-family: $fontName2;
  color: $fontColorText;
  text-align: center;
  transition: all 0.2s ease;
}

.wrCardService:hover .wrCardServiceImg,
.wrCardService:focus .wrCardServiceImg {
  border: 1px solid $fontColorSecond;
  border-bottom: 4px solid $fontColorSecond;
}

.wrCardService:hover .wrCardServiceText,
.wrCardService:focus .wrCardServiceText {
  background: $fontColorSecond;
  color: $fontColorFirst;
}

/* Prices Section */
#prices {
  padding: 130px 0;
  background: $bgColorPrices;
}

.wrPrices {
  padding: 45px 65px 25px;
  background: #f7f8fa;
}

.wrPrices .howtoText {
  color: #000;
}

.table-price__row {
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  font-family: $fontName2;
  font-size: 18px;
  color: #000;
  border-bottom: 1px dashed #1ea6b6;
}

.table-price__row p {
  margin-bottom: 0;
}

.table-price__row-bold {
  font-family: $fontName1;
}

.table-price__row p:first-of-type {
  margin-left: 18px;
  padding-top: 17px;
}

p.table-price__price {
  min-height: 52px;
  min-width: 190px;
  margin: 4px 24px;
  padding-top: 14px;
  text-align: center;
  color: #1ea6b6;
  background-color: #e1f0f3;
}

.table-price__row-second {
  margin-top: 22px;
}

.footerPrices {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 45px 0 0;
  padding: 20px 30px;
  background: $bgColorCardPrices;
}

.footerPricesCol1 {
  width: 65%;
}

.footerPricesCol1 span {
  font-size: $fontSizePricesText;
  font-family: $fontName2;
  color: $fontColorText;
}

/* Documentation Section */
.wrDocumentation {
  padding: 120px 0;
}

.wrDoc {
  margin-top: 60px;
}

.wrBookAnimation {
  text-align: center;
}

.wrBookAnimation img {
  width: 100%;
  height: auto;
}

.wrListDoc {
  border: 1px solid $bgColorWrListDoc;
  background: $bgColorWrListDoc;
}

.listDoc {
  margin: 0;
  padding: 0;
}

.listDoc li {
  display: flex;
  align-items: center;
  margin: 30px;
}

.docImg {
  min-width: 32px;
  min-height: 37px;
  background: url('../img/icon/adobe_icon.svg') no-repeat center;
  background-size: contain;
}

.docTitle {
  margin-left: 30px;
}

.docTitle a {
  font-size: $fontSizeText;
  font-family: $fontName2;
  color: $fontColorFirst;
}

.docTitle a:active {
  text-decoration: underline;
}

/* Feedback Section */
.wrFeedback {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.feedbackCol1 {
  width: 50%;
  padding: 60px 30px;
  background: $bgFeedbackCol1;
  text-align: center;
}

.feedbackCol2 {
  width: 50%;
  padding: 60px 30px;
  background: $bgColorPrices;
  text-align: center;
}

.feedbackTitle {
  font-size: $fontSizeSectionTitle;
  font-family: $fontName2;
  color: $fontColorFirst;
}

.feedbackTitle span {
  font-family: $fontName1;
}

/* Contact Section */
#contact {
  padding: 80px 0;
}

#contact .wrSectionTitle {
  margin-bottom: 60px;
}

.contactCol1 img {
  width: 100%;
  height: auto;
}

.contactTitle {
  font-size: $fontSizeText;
  font-family: $fontName1;
  color: $fontColorText;
}

.contactText {
  font-size: $fontSizeText;
  font-family: $fontName2;
  color: $fontColorText;
  text-align: justify;
}

.contactCol2Bg {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('../img/smart_object_5.png') no-repeat center;
  background-size: contain;
  opacity: .5;
}
