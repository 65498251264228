/* Button Slider's */
.wrIIButton {
  width: 100%;
  text-align: center;
}

.slideIBTN {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 220px;
  margin: auto;
  padding: 10px 15px;
  border: none;
  border-radius: 25px;
  background: $bgColorBtnIntegration;
  font-size: $fontSizeText;
  font-family: $fontName1;
  color: $fontColorFirst;
  outline: none;
  cursor: pointer;
}

.slideIBTN:hover,
.slideIBTN:focus {
  background: $bgColorListIntegration;
  outline: none;
  color: $fontColorBtnSlideHoverFocus;
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.5);
}

/* Button Prices Card's */
.btnBuyPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  margin-top: 15px;
  padding: 10px 15px;
  border: 1px solid $bgColorCardPrices;
  border-radius: 25px;
  background: $bgColorPrices;
  font-size: $fontSizeText;
  font-family: $fontName1;
  color: $bgColorCardPrices;
  text-align: center;
  cursor: pointer;
}

.arrowBTN {
  width: 15px;
  height: 15px;
  margin-left: 25px;
  background: url("../img/icon/right_arrow_blue.svg");
  background-size: cover;
}

.btnBuyPrice:hover,
.btnBuyPrice:focus {
  background: $bgBuyPriceHoverFocus;
  outline: none;
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.5);
}

.btnBuyPrice:hover .arrowBTN,
.btnBuyPrice:focus .arrowBTN {
  background: url("../img/icon/right_arrow_white.svg");
  background-size: cover;
}

/* Buttons Price Footter */
.btnOffers {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  border: none;
  margin: 30px;
  padding: 15px 15px;
  border-radius: 25px;
  background: $bgColorPrices;
  font-size: $fontSizePricesBtn;
  font-family: $fontName1;
  color: $fontColorFirst;
  cursor: pointer;
}

.btnOffers:hover,
.btnOffers:focus {
  background: $fontColorSecond;
  outline: none;
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.5);
}

.btnOffers:hover .arrowBTN,
.btnOffers:focus .arrowBTN {
  background: url("../img/icon/right_arrow_white.svg");
  background-size: cover;
}

/* Buttons Feedback */
.btnFeedbackRequest {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 220px;
  border: none;
  margin: 30px auto 0;
  padding: 15px 15px;
  border-radius: 25px;
  background: $bgColorCard1;
  font-size: $fontSizeDocBtn;
  font-family: $fontName1;
  color: $fontColorText;
  cursor: pointer;
}

.arrowBTNRequest {
  width: 15px;
  height: 15px;
  margin-left: 25px;
  background: url("../img/icon/right_arrow_blue.svg");
  background-size: cover;
}

.btnFeedbackRequest:hover,
.btnFeedbackRequest:focus {
  background: $bgColorPrices;
  outline: none;
  color: $fontColorFirst;
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.5);
}

.btnFeedbackRequest:hover .arrowBTNRequest,
.btnFeedbackRequest:focus .arrowBTNRequest {
  background: url("../img/icon/right_arrow_white.svg");
  background-size: cover;
}

.btnFeedbackSubscribe {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 220px;
  border: none;
  margin: 30px auto 0;
  padding: 15px 15px;
  border-radius: 25px;
  background: $bgColorCard1;
  font-size: $fontSizePricesBtn;
  font-family: $fontName1;
  color: $fontColorText;
  cursor: pointer;
}

.arrowBTNSubscribe {
  width: 15px;
  height: 15px;
  margin-left: 25px;
  background: url("../img/icon/right_arrow_blue.svg");
  background-size: cover;
}

.btnFeedbackSubscribe:hover,
.btnFeedbackSubscribe:focus {
  background: $fontColorSecond;
  outline: none;
  color: $fontColorFirst;
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.5);
}

.btnFeedbackSubscribe:hover .arrowBTNSubscribe,
.btnFeedbackSubscribe:focus .arrowBTNSubscribe {
  background: url("../img/icon/right_arrow_white.svg");
  background-size: cover;
}

/* Button Scroll-Up */
.btnSrollUp {
  display: none; 
  position: fixed; 
  right: 15px;
  bottom: 0; 
  z-index: 9100; 
  border: 1px solid #fff;
  border-bottom: none;
  outline: none; 
  padding: 12px;
  background-color: $bgColorFirst; 
  cursor: pointer;
  opacity: .5;
}

.btnSrollUp,
.btnSrollUp:hover,
.btnSrollUp:focus,
.btnSrollUp:active {
  font-size: $fontSizeText;
  line-height: 100%;
  font-family: $fontName1;
  color: $fontColorFirst;
  text-decoration: none;
}

.btnSrollUp:hover {
  color: $fontColorFourth; 
  opacity: 1;
}