/* Slider Integration */
.wrSlickSlider {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  margin-top: 60px;
}

/* Slider-nav */
.slider-nav {
  width: 40%;
}

.slick-vertical .slick-slide {
  display: flex;
  align-items: center;
}

.leftItemSlick {
  width: auto;
  margin: 10px 0;
  font-size: $fontSizeListIntegration;
  font-family: $fontName2;
  color: $fontColorText;
  text-align: left;
  cursor: pointer;
}

.slick-slide,
.slick-slide:hover,
.slick-slide:focus,
.slick-slide:active {
  border: none;
  outline: none;
}

.slick-active .leftItemSlick {
  color: $fontColorSecond;
}

.slider-nav .slick-active::before {
  display: block;
  content: '';
  width: 15px;
  height: 20px;
  background: url("../img/icon/arrow_right_blue.svg") no-repeat center bottom;
  background-size: contain;
  margin-right: 15px;
}

.slick-arrow {
  position: relative;
  //display: block;
  width: 26px;
  height: 26px;
  //background-color: #d8d8d8;
  border-radius: 50%;
  transition: all .15s linear;
}

.slick-arrow:hover {
  background-color: #40b3c1;
}

.slick-prev {
  top: -35px;
  left: 20%;
  //transform: translateY(-50%);
  background-color: #d8d8d8;
  transform: rotate(90deg);
}

.slick-next {
  top: auto;
  bottom: -35px;
  right: auto;
  left: 20%;
  //transform: translateY(-50%);
  transform: rotate(90deg);
  background-color: #d8d8d8;
}

.slick-prev::before,
.slick-next::before {
  position: absolute;
  display: block;
  height: 13px;
  width: 13px;
  text-align: center;
  font-size: 30px;
  line-height: 100%;
  color: #ccc;
}

.slick-prev::before {
  top: 6px;
  left: 8px;
  border-top: 4px solid #fff;
  border-left: 4px solid #fff;
  transform: rotate(-45deg);
}

.slick-next::before {
  top: 6px;
  left: 5px;
  border-bottom: 4px solid #fff;
  border-right: 4px solid #fff;
  transform: rotate(-45deg);
}

.slick-prev:hover::before,
.slick-next:hover::before {
  //color: $bgColorSlideIntegration;
}

/* Slider-for */
.slider-for {
  position: relative;
  width: 60%;
}

.rightItemSlick {
  position: relative;
  width: 100%;
  max-width: 475px;
  margin: auto;
  min-height: 380px;
  padding: 30px;
  background: $bgSlideIntegration;
  text-align: center;
}

.rightItemSlickBg {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  background: url("../img/slider_pic/woocommerce.png") no-repeat center;
  background-size: contain;
  z-index: 55;
  opacity: .2;
}

.rightItemSlick {
  height: 100%;
}

.itemIntegrationContent {
  position: relative;
  z-index: 60;
  height: 100%;
}

.slideITitle {
  width: 100%;
  font-size: $fontSizeSlideTitle;
  font-family: $fontName1;
  color: $fontColorFirst;
}

.slideIText {
  width: 100%;
  margin: 15px 0;
  font-size: $fontSizeText;
  font-family: $fontName2;
  color: $fontColorFirst;
}

.countRightItemSlick {
  position: absolute;
  top: 100%;
  left: 70%;
  transform: translateX(-50%);
  padding: 5px 15px;
}

.countRightItemSlick,
.currentRIS,
.allRIS {
  font-size: $fontSizeText;
  font-family: $fontName1;
  color: $fontColorSecond;
}
