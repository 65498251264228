/* ================================================== /*
 * @MEDIA SCREEN AND (max-width:1199px)
/* ================================================== */
@media screen and (max-width:1199px) {

  /* Home */
  #home {
    min-height: 380px;
  }

  .homeTitle {
    font-size: 36px;
  }

  /* Howto */
  #animation_container2 {
    display: none;
  }

  .wrHowtoSteps {
    display: block;
  }

  /* Price */
  .wrPrices {
    padding: 30px;
  }

  .navbar-brand {
    min-height: 120px;
    width: 120px;
  }
  /* Footer */
  .copyright {
    margin-top: -30px;
  }
}

/* ================================================== /*
 * @MEDIA SCREEN AND (max-width:991px)
/* ================================================== */
@media screen and (max-width:991px) {

  /* Home */
  #home {
    min-height: 370px;
    overflow-y: visible;
  }

  .wrHomeContent {
    margin: 25px 0 30px;
  }

  .homeTitle {
    font-size: 36px;
  }

  /* Navigation */
  .personalNavbarToggle {
    display: block;
  }

  .navbar {
    padding: 5px 0 0 0;
  }

  .navbar-brand img {
    width: 80px;
  }

  .navbar-brand {
    min-height: 110px;
    width: 110px;
  }

  .navbar-light .navbar-toggler {
    display: none;
    border: none;
  }

  .navbar-collapse {
    border-bottom: 1px solid $fontColorNavItem;
  }

  /* Howto */
  #howto {
    padding: 30px 0;
  }

  .howtoCol1 {
    margin-bottom: 30px;
  }

  .howtoCol1 img {
    width: 40%;
    height: auto;
  }

  .howtoCol2 {
    margin-left: 0;
  }

  .wrHowtoTitle {
    text-align: center;
  }

  .wrHowtoText {
    margin: 30px 0;
  }

  .wrHowtoSteps {
    padding: 30px 15px;
  }

  /* MarketPlaces */
  #marketplFacess {
    padding: 30px 0;
  }

  .marketplaces {
    margin: 30px 0 0 0;
  }

  .marketplace {
    width: 50%;
  }

  /* Integration */
  #integration {
    padding: 30px 0 60px;
  }

  .wrSlider {
    margin: 30px 0;
  }

  /* AddService */
  #addservice {
    padding: 30px 0;
  }

  .wrServiceCards {
    margin-top: 30px;
  }

  /* Price */
  #prices {
    padding: 30px 0;
  }

  .wrCardPrices {
    margin-top: 74px;
  }

  .footerPrices {
    margin: 30px 0;
  }

  .footerPricesCol1 {
    width: 100%;
  }

  .btnOffers {
    margin: 30px 0;
  }

  /* Documentation */
  .wrDocumentation {
    padding: 30px 0;
  }

  .wrDoc {
    margin-top: 30px;
  }

  /* Feedback */
  .feedbackCol1,
  .feedbackCol2 {
    padding: 30px 15px;
  }

  .feedbackTitle {
    font-size: 22px;
  }

  /* Contact */
  #contact {
    padding: 30px 0;
  }

  #contact .wrSectionTitle {
    margin-bottom: 30px;
  }

  .contactCol1 {
    display: none;
  }

  .contactCol2Bg {
    display: block;
  }

  /* Footer */
  .footerRow1 {
    flex-wrap: wrap;
  }

  .footerCol1 img {
    width: 46px;
  }

  .footerCol1 {
    margin: 0 auto 30px;
  }

  .copyright {
    margin-top: 15px;
  }

  .wr2FooterCol {
    margin-left: 0;
  }

  .footerCol2,
  .footerCol4 {
    margin: 0;
  }
}

/* ================================================== /*
 * @MEDIA SCREEN AND (max-width:915px)
/* ================================================== */
@media screen and (max-width:915px) {

  /* Home */
  #home {
    min-height: 340px;
  }
}

/* ================================================== /*
 * @MEDIA SCREEN AND (max-width:843px)
/* ================================================== */
@media screen and (max-width:843px) {

  /* Home */
  #home {
    min-height: 315px;
  }
}

/* ================================================== /*
 * @MEDIA SCREEN AND (max-width:767px)
/* ================================================== */
@media screen and (max-width:767px) {

  /* Home */
  #home {
    min-height: auto;
    background: url(../img/bg.png) no-repeat top center;
    background-size: cover;
  }

  #animation_container {
    display: none;
  }

  .wrHomeContent {
    margin: 30px 0 60px;
  }

  /* Howto */
  #howto {
    padding: 25px 0;
  }

  .howtoCol1 {
    margin-bottom: 25px;
  }

  /* Marketplaces */
  .marketplace {
    width: 50%;
  }

  .marketplace:nth-child(2n) .linkMarketplace {
    padding-left: 15px;
  }

  /* Integration */
  .wrSlickSlider {
    margin-top: 30px;
  }

  .slider-nav {
    display: none;
  }

  .slider-for {
    width: 100%;
  }

  .countRightItemSlick {
    left: 50%;
  }

  /* Documentation */
  .wrBookAnimation {
    margin-bottom: 30px;
  }

  .wrBookAnimation img {
    width: 60%;
  }

  /* Feedback */
  .feedbackCol1,
  .feedbackCol2 {
    width: 100%;
  }

  .feedbackTitle {
    font-size: 22px;
  }

  /* Contact */
  .contactCol1 {
    margin-bottom: 30px;
  }
}

/* ================================================== /*
 * @MEDIA SCREEN AND (max-width:575px)
/* ================================================== */
@media screen and (max-width:575px) {

  /* Home */
  #exception1 {
    margin-top: 65px;
  }

  /* Navigation */
  .personalNavbarToggle {
    top: 22px;
  }

  .navbar-brand img {
    margin-top: 5px;
    width: 60px;
  }

  .navbar-brand {
    min-height: 80px;
    width: 80px;
  }

  .personalNavbarToggle {
    top: 15px;
  }

  .wrHomeContent{
    margin: 15px 0 45px;
  }

  .homeTitle {
    font-size: 28px;
  }

  .homeSubtitle {
    font-size: 18px;
  }

  /* Howto */
  #howto {
    padding: 15px 0;
  }

  .howtoCol1 {
    margin-bottom: 15px;
  }

  .sectionTitle {
    font-size: 28px;
  }

  .wrHowtoText {
    margin: 15px 0;
  }

  .howtoText {
    font-size: 16px;
  }

  .wrHowtoSteps {
    padding: 15px;
  }

  /* Marketplaces */
  #marketplacess {
    padding: 15px 0;
  }

  .wrMarketplaces {
    padding: 15px;
  }

  .marketplaces {
    margin: 15px 0 0 0;
  }

  .marketplace:nth-child(2n+1) {
    padding-right: 8px;
  }

  .marketplace:nth-child(2n) {
    padding-left: 8px;
  }

  .marketplaceTitle {
    font-size: 16px;
  }

  /* Puzzle */
  #puzzle {
    display: none;
  }

  /* Integration */
  .wrSlickSlider {
    margin-top: 15px;
  }

  .rightItemSlick {
    min-height: 290px;
    padding: 15px;
  }

  .slideITitle {
    font-size: 28px;
  }

  .slideIText {
    font-size: 16px;
  }

  /* AddService */
  #addservice {
    padding: 15px 0;
  }

  .wrServiceCards {
    margin-top: 15px;
  }

  .wrCardService {
    width: 40%;
  }

  .wrCardServiceText {
    font-size: 16px;
  }

  /* Prices */
  #prices {
    padding: 15px 0;
  }

  .wrPrices {
    padding: 15px;
  }

  .footerPrices {
    margin: 15px 0 0;
    padding: 20px 20px;
  }

  .btnOffers {
    margin: 15px 0;
  }

  /* Documentation */
  .wrDocumentation {
    padding: 15px 0;
  }

  .wrDoc {
    margin-top: 15px;
  }

  .wrBookAnimation {
    margin-bottom: 15px;
  }

  .listDoc li {
    margin: 15px;
  }

  .docTitle a {
    font-size: 16px;
  }

  /* Feedback */
  .feedbackCol1, .feedbackCol2 {
    padding: 15px;
  }

  .btnFeedbackRequest,
  .btnFeedbackSubscribe {
    margin: 15px auto 0;
  }

  /* Contact */
  #contact {
    padding: 15px 0;
  }

  #contact .wrSectionTitle {
    margin-bottom: 15px;
  }

  .contactCol1 {
    margin-bottom: 15px;
  }

  .contactText {
    font-size: 16px;
  }

  /* Footer */
  #footer {
    padding: 15px 0;
  }

  .footerCol1 {
    margin-bottom: 15px;
  }

  .wr2FooterCol {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footerCol2,
  .footerCol3,
  .footerCol4 {
    justify-content: center;
    width: 100%;
    margin: 15px 0;
  }

  .footerRow2 {
    margin-top: 15px;
  }

  .footerTitle {
    text-align: center;
  }

}

/* ================================================== /*
 * @MEDIA SCREEN AND (max-width:467px)
/* ================================================== */
@media screen and (max-width:467px) {

  /* Home */
  .subMenu li:first-child {
    margin-top: 130px;
  }

  .openMobNav .subMenu {
    width: 450px;
    height: 450px;
  }

  .openMobNav span:nth-child(2) {
    width: 450px;
    height: 450px;
    top: -100px;
  }

  .homeTitle {
    width: 80%;
    margin: auto;
    font-size: 18px;
  }

  .homeSubtitle {
    width: 80%;
    margin: auto;
    font-size: 14px;
  }

  /* Howto */
  .sectionTitle {
    font-size: 24px;
  }

  /* Marketplaces */
  .marketplace {
    width: 100%;
  }

  .linkMarketplace {
    justify-content: center;
  }

  .marketplace:nth-child(2n+1) {
    padding: 0;
  }

  .marketplace:nth-child(2n),
  .marketplace:nth-child(2n) .linkMarketplace {
    padding: 0;
  }

  .linkMarketplace,
  .marketplace:nth-child(2n) .linkMarketplace{
    justify-content: flex-start;
    padding-left: calc(50% - 110px);
  }

  .marketplace:last-child {
    margin: 10px 0 0 0;
  }

  /* Integration */
  .rightItemSlick {
    min-height: 318px;
  }

  .slideITitle {
    font-size: 24px;
  }

  /* AddService */
  .wrCardService {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .wrCardServiceImg {
    max-width: 100px;
    height: 100px;
    flex: 1 0 100px;
  }

  .wrCardServiceText {
    width: auto;
    height: 100px;
    flex: 0 1 auto;
    padding: 15px 0 15px 30px;
    text-align: left;
  }

  .product-conf img {
    max-width: 57px;
  }

  .product-maint img {
    max-width: 43px;
  }

  .translation img {
    max-width: 80px;
  }

  .design img {
    max-width: 74px;
  }

  .develop  img {
    max-width: 65px;
  }

  .content img {
    max-width: 75px;
  }

  .optimization img {
    max-width: 61px;
  }

  /* Prices */
  .btnBuyPrice {
    padding: 5px 15px;
  }

  .btnOffers {
    padding: 10px 15px;
    width: 200px;
  }

  .table-price__row {
    font-size: 16px;
  }

  .table-price__row  p:first-of-type {
    margin-left: 0;
  }

  p.table-price__price {
    margin: 4px 0;
  }

  .table-price__row {
    flex-direction: column;
    align-items: center;
  }

  p.table-price__price,
  .table-price__row-bold p {
    margin-bottom: 15px;
  }

  /* Feedback */
  .feedbackTitle {
    font-size: 20px;
  }

  .btnFeedbackRequest,
  .btnFeedbackSubscribe {
    padding: 10px 15px;
  }
}
