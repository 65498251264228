@font-face {
  font-family: Montserrat_Italic;
  src: url(../fonts/Montserrat_Italic.woff);
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Montserrat_Regular;
  src: url(../fonts/Montserrat_Regular.woff);
  font-weight: 400; // normal
  font-style: normal;
}

@font-face {
  font-family: Montserrat_Bold;
  src: url(../fonts/Montserrat_Bold.woff);
  font-weight: 700;
  font-style: normal;
}
