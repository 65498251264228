/* Mobile Navigation */
.overlayMobNav {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  background: $bgOverlayMobNav;
  opacity: .5;
}

.personalNavbarToggle { 
  display: none;
  position: absolute;
  top: 25px;
  right: 6px;
  width: 40px;
  height: 37px;
  cursor: pointer;
  transition: opacity 0.2s linear;
}

.personalNavbarToggle:hover { 
  opacity: 0.8;
}

.personalNavbarToggle span {
  display: block;
  float: left;
  clear: both;
  position: absolute;
  top: 5px;
  right: 5px;
  left: auto;
  width: 30px;
  height: 4px;
  border-radius: 40px;
  background-color: $bgPersonalNavbarToggleSpan;
  overflow: hidden;
  transition: all 0.4s ease;
}

.personalNavbarToggle span:nth-child(1) {
  margin-top: 0;
  z-index: 9250;
}

.personalNavbarToggle span:nth-child(2) { 
  margin-top: 10px; 
}

.personalNavbarToggle span:nth-child(3) { 
  margin-top: 20px; 
}

.subMenu {
  z-index: 9260;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  overflow-x: hidden;
  border-radius: 50%;
  background-color: $bgSubMenu;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.subMenu li {
  display: block;
  float: right;
  clear: both;
  height: auto;
  margin-right: -160px;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.subMenu li:first-child { 
  margin-top: 180px; 
}

.subMenu li:nth-child(1) { 
  -webkit-transition-delay: 0.05s; 
}

.subMenu li:nth-child(2) { 
  -webkit-transition-delay: 0.10s; }

.subMenu li:nth-child(3) { 
  -webkit-transition-delay: 0.15s; 
}

.subMenu li:nth-child(4) { 
  -webkit-transition-delay: 0.20s; }

.subMenu li:nth-child(5) { 
  -webkit-transition-delay: 0.25s; 
}

.subMenu li:nth-child(6) { 
  -webkit-transition-delay: 0.30s; 
}

.subMenu li:nth-child(7) { 
  -webkit-transition-delay: 0.35s; 
}

.subMenuItem {
  display: block;
  float: left;
  width: 100%;
  font-size: $fontSizeText;
  line-height: 200%;
  font-family: $fontName1;
  color: $fontColorFirst;
}

.subMenuItem:hover,
.subMenuItem:focus {
  color: $fontColorSubMenuHoverFocus;
  text-decoration: none;
}

.openMobNav .subMenu {
  width: 500px;
  height: 500px;
  opacity: 1;
}

.openMobNav span:nth-child(1),
.openMobNav span:nth-child(3) {
  z-index: 9300;
  transform: rotate(45deg);
}

.openMobNav span:nth-child(2) { 
  overflow: visible;
}

.openMobNav span:nth-child(1) { 
  transform: rotate(45deg) translateY(4px) translateX(8px); 
}

.openMobNav span:nth-child(2) {
  width: 500px;
  height: 500px;
  top: -150px;
  right: -100px;
  border-radius: 50%;
  background-color: $bgSubMenu2;
}

.openMobNav span:nth-child(3) { 
  transform: rotate(-45deg) translateY(-6px) translateX(10px); 
}

.openMobNav li { 
  margin-right: 100px; 
}