#footer {
  padding: 30px 0;
  background: $secondColor;
}

.wrFooter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.footerRow1 {
  display: flex;
  justify-content: space-between;
}

.footerCol1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: 30px;
}

.copyright {
  width: 100%;
  margin-top: 30px;
  font-size: $fontSizeSmallText;
  font-family: $fontName2;
  color: $fourthColor;
  text-align: center;
}

.footerCol1 img {
  margin: 0 auto 5px;
  display: block;
  margin-bottom: 5px;
  width: 54px;
  height: auto;
}

.footerCol1 span {
  margin: 0 auto;
  display: block;
  color: #fff;
  font-family: Montserrat_Regular, Arial, sans-serif;
  font-size: 18px;
}

.footerCol1 a {
  text-decoration: none;
}

.wr2FooterCol {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  width: 100%;
  margin-left: 30px;
}

.footerCol2,
.footerCol3,
.footerCol4 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 30%;
  margin: 0 30px;
}

.footerCol4 {
  width: 39%;
  margin-right: 0;
}

.footerTitle {
  width: 100%;
  margin-bottom: 15px;
  font-size: $fontSizeH3;
  font-family: $fontName1;
  color: $firstColor;
  text-transform: uppercase;
}

.companyList,
.supportList {
  display: block;
  margin: 0;
  padding: 0 0 0 15px;
  color: $firstColor;
  list-style-type: none;
}

.companyList li,
.supportList li {
  position: relative;
}

.companyList li::before,
.supportList li::before {
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  content: '';
  background: $firstColor;
}

.companyList a,
.supportList a {
  font-size: $fontSizeNavFooter;
  font-family: $fontName2;
  color: $fourthColor;
}

.companyList a:hover,
.companyList a:focus,
.supportList a:hover,
.supportList a:focus {
  color: $firstColor;
  text-decoration: none;
}

a.footerPageActive {
  color: $firstColor;
}

.footerText {
  font-size: $fontSizeSmallText;
  font-family: $fontName2;
  color: $fourthColor;
  text-align: justify;
}

.footerRow2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px 0 0 0;
}

.listFooterIcon {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.listFooterIcon li {
  display: inline-block;
  margin: 0 15px;
}

.itemFooterIcon {
  width: 32px;
  height: 32px;
}

.itemInstagram .itemFooterIcon {
  background: url(../img/icon/instagram_icon.svg) no-repeat center;
  background-size: cover;
}

.itemYoutube .itemFooterIcon {
  width: 41px;
  height: 32px;
  background: url(../img/icon/youtube_icon.svg) no-repeat center;
  background-size: cover;
}

.itemFacebook .itemFooterIcon {
  background: url(../img/icon/facebook_icon.svg) no-repeat center;
  background-size: cover;
}

.itemInstagram:hover .itemFooterIcon,
.itemInstagram:focus .itemFooterIcon,
.itemInstagram:active .itemFooterIcon {
  background: url(../img/icon/instagram_icon_white.svg) no-repeat center;
  background-size: cover;
}

.itemYoutube:hover .itemFooterIcon,
.itemYoutube:focus .itemFooterIcon,
.itemYoutube:active .itemFooterIcon {
  background: url(../img/icon/youtube_icon_white.svg) no-repeat center;
  background-size: cover;
}

.itemFacebook:hover .itemFooterIcon,
.itemFacebook:focus .itemFooterIcon,
.itemFacebook:active .itemFooterIcon {
  background: url(../img/icon/facebook_icon_white.svg) no-repeat center;
  background-size: cover;
}

.listFooterIcon a,
.listFooterIcon a:hover,
.listFooterIcon a:focus,
.listFooterIcon a:active {
  outline: none;
}
